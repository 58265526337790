import axios from 'axios';
import AuthRepository from '../../domain/repository/auth/AuthRepository';

class AuthApi implements AuthRepository {
    register = (type: string, email: string, password: string): any => {
        return axios({
            method: 'POST',
            url: 'users',
            data: {
                type: type,
                email: email,
                plainPassword: password,
            },
        });
    };

    login = (email: string, password: string): any => {
        return axios({
            method: 'POST',
            url: axios.defaults.baseURL?.slice(0, -4) + 'auth',
            data: {
                email: email,
                password: password,
            },
        });
    };

    getFreshToken = (refreshToken: string): any => {
        return axios({
            method: 'POST',
            url: axios.defaults.baseURL?.slice(0, -4) + 'token/refresh',
            data: {
                refresh_token: refreshToken,
            },
        });
    };

    googleLogin = (googleToken: string): any => {
        return axios({
            method: 'POST',
            url: axios.defaults.baseURL?.slice(0, -4) + 'google-auth',
            data: {
                accessToken: googleToken,
            },
        });
    };
}

export default AuthApi;
