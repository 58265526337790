import React from 'react';
import Field from '../../../components/Field';


// forms
import { Form, Field as FinalField } from 'react-final-form'

// styles
import st from '../Auth.module.scss';

// icons
// import EmailIco from '../../public/images/icons/email.svg';
// import LockIco from '../../public/images/icons/lock.svg';
import { Link, useHistory } from 'react-router-dom';

import Button from '../../../components/Button';

import AuthViewModel from '../../../view-model/auth/AuthViewModel';
import { observer } from 'mobx-react';
import { useViewModels } from '../../../view-model/ViewModelProvider';
import GoogleLogin from 'react-google-login';
import Logo from '../../../components/Logo';

export interface AuthComponentProps {
    authViewModel?: AuthViewModel;
}


const Signup: React.FC<AuthComponentProps> = observer(() => {

    const { authViewModel: {
        onClickSignUp,
        signUpSubmitError,
        onClickGoogleLogin,
        isShowError,
    } } = useViewModels();

    const history = useHistory();

    const onSubmit = async (values: { type: string, email: string, password: string, passwordClone: string }) => {
        onClickSignUp(values.type, values.email, values.password)
            .then(({ status }) => {
                if (status === 201) {
                    history.push('/account/signin')
                }
            })
    }


    return (
        <div className={st.page}>
            <div className={st.container}>
                <a className={st.to_homepage} href='https://feasibl.dev.basis33.com/'>Back to homepage</a>
                <div className={st.parts}>
                    <div className={st.part}>
                        <a href='https://feasibl.dev.basis33.com/'><Logo color='accent' className={st.logo} /></a>
                        <header className={st.header}>
                            <span>Start For Free</span>
                            <h1>Sign Up</h1>
                            <p>
                                Already a member? <Link to='/account/signin'>Log In</Link>
                            </p>
                        </header>
                        <Form
                            initialValues={
                                { type: 'client' }
                            }
                            onSubmit={onSubmit}
                            validate={(values) => {
                                const errors: any = {};
                                if (values.email === undefined) {
                                    errors.email = 'Is required';
                                }
                                if (values.type === undefined) {
                                    errors.type = 'Is required';
                                }
                                if (values.password === undefined) {
                                    errors.password = 'Is required';
                                }
                                if (values.passwordClone === undefined) {
                                    errors.passwordClone = 'Is required';
                                }
                                if (values.password?.length < 6) {
                                    errors.password = 'less than 6';
                                } else {

                                    if (values.passwordClone !== values.password) {
                                        errors.passwordClone = 'Dosn`t match';
                                        errors.password = 'Dosn`t match';
                                    }
                                }
                                return errors
                            }}
                            render={({ handleSubmit, dirtyFieldsSinceLastSubmit }) => (
                                <form className={st.form} onSubmit={handleSubmit}>
                                    <main className={st.form__main}>
                                        <div className={st.form__field}>
                                            <FinalField name='email' render={({ input, meta }) => (
                                                <Field
                                                    title='Email'
                                                    error={meta.error}
                                                    isInvalid={meta.error && meta.touched && !meta.dirtySinceLastSubmit}
                                                >
                                                    <input
                                                        {...input}

                                                        type='email'
                                                        placeholder='name@mail.com'
                                                    />
                                                </Field>
                                            )} />
                                        </div>
                                        <div className={st.form__field_row}>
                                            <div className={st.form__field}>
                                                <FinalField name='password' render={({ input, meta }) => (
                                                    <Field title='Password'
                                                        error={meta.error}
                                                        isInvalid={meta.error && meta.touched && !meta.dirtySinceLastSubmit}
                                                    >
                                                        <input type='password'  {...input} placeholder='6+ characters' />
                                                    </Field>
                                                )} />
                                            </div>
                                            <div className={st.form__field}>
                                                <FinalField name='passwordClone' render={({ input, meta }) => (
                                                    <Field
                                                        title='Confirm'
                                                        error={meta.error}
                                                        isInvalid={meta.error && meta.touched && !meta.dirtySinceLastSubmit}
                                                    >
                                                        <input type='password' {...input} placeholder='6+ characters' />
                                                    </Field>
                                                )} />
                                            </div>
                                        </div>
                                        <div className={st.form__field}>
                                            <FinalField name='type' render={({ input, meta }) => (
                                                <Field
                                                    title='Profile type'
                                                    error={meta.error}
                                                    isInvalid={meta.error && meta.touched && !meta.dirtySinceLastSubmit}
                                                >
                                                    <input type='text' {...input} placeholder='owner, client' />
                                                </Field>
                                            )} />
                                        </div>
                                        {isShowError && !Object.keys(dirtyFieldsSinceLastSubmit).length &&
                                            <p className={st.error}>
                                                {signUpSubmitError}
                                            </p>
                                        }
                                    </main>

                                    <div className={st.form__buttons}>
                                        <div className={st.form__button}>
                                            <Button color='accent' type='submit'>
                                                Create an account
                                            </Button>
                                        </div>
                                        <GoogleLogin
                                            clientId='740071620552-usl3ajgqv6cdhjls9bbun7p6d6rcjr0d.apps.googleusercontent.com'
                                            render={renderProps => (
                                                <Button onClick={renderProps.onClick} color='outline'>
                                                    Sign Up With Google
                                                </Button>
                                            )}
                                            buttonText='Login'
                                            onSuccess={({ accessToken }: any) => {
                                                onClickGoogleLogin(accessToken)
                                            }}
                                            onFailure={(e) => {
                                                console.log(e);

                                            }}
                                        />
                                        {/* <div className={st.form__button}>
                                            <Button color='outline'>
                                                Sign Up With Google
                                            </Button>
                                        </div> */}
                                    </div>

                                    <p className={st.form__paragraph}>
                                        By creating account I agree with
                                        <Link to='#'>
                                            Terms and Conditions
                                        </Link>
                                            and
                                        <Link to='#'>
                                            Privacy Policy
                                        </Link>
                                    </p>

                                </form>

                            )}
                        />


                    </div>
                </div>
            </div>
        </div>
    )
})


export default Signup
