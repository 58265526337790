import React from 'react';

// components
import Button from '../../components/Button';

// styles
import st from './InvestmentPreview.module.scss';
import classNames from 'classnames';

// icons
import BookMarkIco from '../../assets/icons/bookmark.svg';
import FractionsIco from '../../assets/icons/fractions.svg';
import SponsoredIco from '../../assets/icons/sponsored.svg';
import ArtIco from '../../assets/icons/cat_art.svg';
import ReservedIco from '../../assets/icons/reserved.svg';
import SaleIco from '../../assets/icons/sale.svg';


type LegendsType = {
    name: string,
    type: string
    value: string
    Icon: string
}

type InvestmentPreviewType = {
    image: string,
    title: string
    featured?: boolean
    clickable?: boolean
    hasInvestBtn?: boolean
    short_desc: string
    desc: string
    cat: string
    legends?: Array<LegendsType>
}

const InvestmentPreview: React.FC<InvestmentPreviewType> = ({
    image,
    title,
    featured,
    clickable,
    hasInvestBtn,
    short_desc,
    desc,
    cat,
    legends = [
        {
            name: 'Reserved',
            type: 'reserved',
            value: '0',
            Icon: ReservedIco
        },
        {
            name: 'Total fractions: 20',
            type: 'fractions',
            value: 1,
            Icon: FractionsIco
        },
        {
            name: 'Available for sale: 1',
            type: 'available',
            value: 2,
            Icon: SaleIco
        }
    ]
}) => {
    const classes = classNames(
        st.container, {
        [st.clickable]: clickable
    }
    )
    return (
        <div className={classes}>
            <div className={st.picture}>
                <img src={image} alt='' />
                <span className={st.status}>
                    143 FOR SALE
                </span>
            </div>
            <div className={st.main}>
                <header className={st.header}>
                    <h2 className={st.title}>
                        {title}
                    </h2>
                    {featured &&
                        <div className={st.featured}>
                            <img src={SponsoredIco} alt='' />
                        </div>
                    }
                    <div className={st.cat}>
                        <img src={ArtIco} alt='' />
                        {cat}
                    </div>
                    <div className={st.bookmark}>
                        <img src={BookMarkIco} alt='' />
                    </div>
                    <div className={st.short_desc}>
                        {short_desc}
                    </div>
                </header>
                <main className={st.body}>
                    <p>
                        {desc}
                    </p>
                </main>
                <footer className={st.footer}>
                    <div className={st.legends}>
                        {legends.map((legend, i) => {
                            return (
                                <div
                                    className={st.legend}
                                    key={i}
                                >
                                    <img src={legend.Icon} alt='' />
                                    {legend.name}:
                                    <span> {legend.value}</span>
                                </div>
                            )
                        })}
                    </div>
                    <div className={st.tags}>
                        <div className={st.tag}>
                            <Button color='light'>
                                <strong>₿ 1</strong>
                                Minimum
                            </Button>
                        </div>
                        <div className={st.tag}>
                            <Button color='light'>
                                <strong>₿ 1</strong>
                                Minimum
                            </Button>
                        </div>
                        <div className={st.tag}>
                            <Button color='light'>
                                <strong>₿ 1</strong>
                                Minimum
                            </Button>
                        </div>
                    </div>
                    {hasInvestBtn &&
                        <div className={st.invest_btn}>
                            <Button color='accent'>
                                Invest in Piece of Art
                        </Button>
                        </div>
                    }
                </footer>
            </div>
        </div>
    )
}


export default InvestmentPreview
