import React from 'react';
import st from './WithBlur.module.scss';

const {
    container
} = st;

const WithBlur: React.FC = ({
    children
}) => {
    return (
        <span className={container}>
            {children}
        </span>
    )
}


export default WithBlur
