import { useTable } from "react-table";
import st from "./Table.module.scss"
import cn from "classnames";


type TableColumns = {
    Header: string
    accessor: string
}

type TableProps = {
    columns: Array<TableColumns>,
    data: Array<any>
    className: string
    dashed?: boolean
}


const Table: React.FC<TableProps> = ({
    columns,
    data,
    className,
    dashed
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    });

    return (
        <table className={cn(
            st.table,
            className
        )} {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th className={st.th} {...column.getHeaderProps()}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                    rows.map(row => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                            // Apply the row props
                            <tr className={st.tr} {...row.getRowProps()}>
                                {// Loop over the rows cells
                                    row.cells.map(cell => {
                                        // Apply the cell props
                                        return (
                                            <td vertical-align='middle' className={cn(st.td, {
                                                [st.td__dashed] : dashed}
                                            )} {...cell.getCellProps()}>
                                                {// Render the cell contents
                                                    cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                            </tr>
                        )
                    })}
            </tbody>
        </table>
    )
}

export default Table
