import React from 'react';
import st from './UserAvatar.module.scss';

type UserAvatarProps = {
    src?: 'string'
}

const UserAvatar: React.FC<UserAvatarProps> = ({
    src = '/images/avatar-blue.jpg',
}) => {
    return (
        <div className={st.container}>
            <img src={src} alt='avatar' />
        </div>
    )
}


export default UserAvatar
