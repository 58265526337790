import React, { useMemo } from 'react';
import st from './Prizes.module.scss';
import cn from "classnames";


// components
import DefaultSection from "../../../components/sections/DefaultSection";
import Button from '../../../components/Button';
import Table from "../../../components/Table";

//images || icons
// import ScoreboardIco from '../../assets/icons/scoreboard.svg';
import PrisezIcon from '../../../assets/icons/menus/PrizesIconPurpur.svg';

const Prisez: React.FC = () => {
   const data = useMemo(
        () => [
            {
                col1: '313123',
                col2: 'Stocks',
                col3: '$12,2812.92',
                col4: '11/02/21',
                col5: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '12312',
                col2: 'Stocks',
                col3: '$12,2812.92',
                col4: '11/02/21',
                col5: <Button size='xxs' color='empty'>View</Button>,
            },
        ],
        []
    )

    const columns = useMemo(
        () => [
            {
                Header: 'Invest ID',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Type',
                accessor: 'col2',
            },
            {
                Header: 'Amount',
                accessor: 'col3',
            },
            {
                Header: 'Date',
                accessor: 'col4',
            },
            {
                Header: 'View',
                accessor: 'col5',
            },
        ],
        []
    )
    console.log(st);
    return (
        <div className={st.container}>
            <div className={st.winrate__wrap}>
                <div className={cn(st.winrate__item, st.winrate__item_is_prisez)}>
                    <div className={cn(st.winrate__box, st.winrate__box_is_diagram)}>
                       <img src={"/images/diagram.svg"} alt=''/>
                    </div>
                    <div className={cn(st.winrate__box, st.winrate__box_is_text)}>
                        <p className={st.winrate__title}>Your Prizes winrate</p>
                        <p className={st.winrate__desc}>You have great chances 🔥</p>
                    </div>
                </div>
                <div className={cn(st.winrate__item, st.winrate__item_is_hot)}>
                    <p className={st.hot__title}>
                        <div className={st.hot__pic}>
                            <img src={"/images/chat.svg"} alt=''/>
                        </div>
                        <span>Hot</span></p>
                    <div className={st.hot__wrap}>
                        <div className={st.hot__item}>
                            <div className={st.hot__image}>
                                <img src={"/images/hot_1.svg"} alt=''/>
                            </div>
                            <p className={st.hot__subtitle}>Company Name</p>
                            <p className={st.hot__desc}>Stocks</p>
                            <p className={st.hot__count}>200/1000</p>
                            <button className={st.hot__btn}></button>
                        </div>
                        <div className={st.hot__item}>
                            <div className={st.hot__image}>
                                <img src={"/images/hot_2.svg"} alt=''/>
                            </div>
                            <p className={st.hot__subtitle}>Company Name</p>
                            <p className={st.hot__desc}>Crowdfounding</p>
                            <p className={st.hot__count}>200/1000</p>
                            <button className={st.hot__btn}></button>
                        </div>
                    </div>
                </div>
            </div>
            <DefaultSection rightBlock headPadding icon={PrisezIcon} title='Prisez Details'>
                    <Table dashed columns={columns} data={data} className={cn(st.table, st.winrate__is_table)} />
            </DefaultSection>
        </div>
    );
}

export default Prisez;
