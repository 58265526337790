import React from 'react';
import st from './DefaultSection.module.scss';
import CustomSelect from "../../CustomSelect";
import cn from "classnames"

type DefaultSectionProps = {
    title: string
    icon: any
    className: string
    rightBlock: boolean
    rightBlockTitle?: string
    headPadding?: boolean
    children: any
}

const DefaultSection: React.FC<DefaultSectionProps> = ({
    title,
    icon,
    rightBlock,
    rightBlockTitle,
    headPadding,
    children,
}) => {
    return (
        <div className={st.section}>
            <div className ={cn(st.section__head, {
                [st.section__head_padding]: headPadding}
            )}>
                <div className={st.title}>
                    {icon &&
                        <div className={st.icon}><img src={icon} alt={title} /></div>
                    }
                    {title}
                </div>
                {rightBlock &&
                    <div className={st.right_block}>
                        <div className={st.right_block__title}>{rightBlockTitle}</div>
                        <CustomSelect
                            placeholder=''
                            className=''
                            onSelect={() => {
                            }}
                            alignIcon=''
                            list={[
                                {
                                    name: 'name 1',
                                    value: 'value 1'
                                },
                                {
                                    name: 'name 2',
                                    value: 'value 2'
                                },
                                {
                                    name: 'name 3',
                                    value: 'value 3'
                                }
                            ]}
                            value='This Month'
                        />
                    </div>
                }
            </div>
            <div className={st.body}>
                {children}
            </div>
        </div>
    )
}

export default DefaultSection
