import React from 'react';
import st from './NotificationIndicator.module.scss';

import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';

const NotificationIndicator = () => {
    return (
        <div className={st.container}>
            <div className={st.indicator}></div>
            <BellIcon />
        </div>
    );
};

export default NotificationIndicator;
