import React from 'react';
import classNames from 'classnames/bind';
import styles from './Button.module.scss';
import cn from "classnames";
let cx = classNames.bind(styles);

type ButtonProps = {
    theme?: string,
    size?: string,
    color?: string,
    hover?: string,
    fillAllWidth?: boolean,
    onClick?: Function,
    className?: string,
    unclickable?: boolean
    type?: 'button' | 'submit' | 'reset' | undefined
}

const Button: React.FC<ButtonProps> = ({
    theme = 'default',
    size = 'md',
    color = 'primary',
    hover = 'standart',
    fillAllWidth = false,
    children,
    type = 'button',
    unclickable,
    onClick = () => { },
    className
}) => {
    const classes = cx(
        {
            button: true,
            // themes
            theme_default: theme === 'default',
            // sizes
            size_lg: size === 'lg',
            size_md: size === 'md',
            size_sm: size === 'sm',
            size_xs: size === 'xs',
            size_xxs: size === 'xxs',
            // colors
            color_accent: color === 'accent',
            color_accent_reverse: color === 'accent-reverse',
            color_accent_transparent: color === 'accent-transparent',
            color_green_transparent: color === 'green_transparent',
            color_sea_dashed: color === 'sea_dashed',
            color_primary: color === 'primary',
            color_secondary: color === 'secondary',
            color_outline: color === 'outline',
            color_light: color === 'light',
            color_empty: color === 'empty',
            color_sq_green: color === 'sq_green',
            color_sq_yellow: color === 'sq_yellow',
            color_sq_red: color === 'sq_red',
            color_light_transparent: color === 'light-transparent',
            // hovers
            hover_standart: hover === 'standart',
            // helpers
            fill_all_width: fillAllWidth
        },
        className && className
    );

    const clickHandler = () => {
        onClick()
    }

    return (
        <button className={cn(classes, {
            [styles.unclickable] : unclickable}
        )} onClick={clickHandler} type={type}>
            <p className={cx({ content: true })}>
                {children}
            </p>
        </button>
    )
}


export default Button
