import React, { useMemo } from 'react';

// components
import Button from '../../../components/Button';
import Table from "../../../components/Table"
import DefaultSection from '../../../components/sections/DefaultSection';
import MyInvestmnetsIco from '../../../assets/icons/MyInvestmnetsIco.svg';
import graphUp from '../../../assets/icons/graphUp.svg';

// styles
import st from './Investments.module.scss';
import { Link } from 'react-router-dom';

const Investments: React.FC = () => {

    const data = useMemo(
        () => [
            {
                col1: '22411224',
                col2: 'Full name',
                col3: 'Stocks',
                col4: '$2132.32',
                col5: '11/11/21',
                col6: <span>Paid</span>,
                col7: <img src={graphUp} alt='' />,
                col8: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: 'Stocks',
                col4: '$2132.32',
                col5: '11/11/21',
                col6: <span>Paid</span>,
                col7: <img src={graphUp} alt='' />,
                col8: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: 'Stocks',
                col4: '$2132.32',
                col5: '11/11/21',
                col6: <span>Paid</span>,
                col7: <img src={graphUp} alt='' />,
                col8: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: 'Stocks',
                col4: '$2132.32',
                col5: '11/11/21',
                col6: <span>Paid</span>,
                col7: <img src={graphUp} alt='' />,
                col8: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: 'Stocks',
                col4: '$2132.32',
                col5: '11/11/21',
                col6: <span>Paid</span>,
                col7: <img src={graphUp} alt='' />,
                col8: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: 'Stocks',
                col4: '$2132.32',
                col5: '11/11/21',
                col6: <span>Paid</span>,
                col7: <img src={graphUp} alt='' />,
                col8: <Button size='xxs' color='empty'>View</Button>,
            },
        ],
        []
    )

    const columns = useMemo(
        () => [
            {
                Header: 'Invest ID',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'col2',
            },
            {
                Header: 'Type',
                accessor: 'col3',
            },
            {
                Header: 'Amount',
                accessor: 'col4',
            },
            {
                Header: 'Date',
                accessor: 'col5',
            },
            {
                Header: 'Status',
                accessor: 'col6',
            },
            {
                Header: 'Graph',
                accessor: 'col7',
            },
            {
                Header: 'View',
                accessor: 'col8',
            },
        ],
        []
    )

    return (
        <div className={st.container}>
            <DefaultSection rightBlock headPadding icon={MyInvestmnetsIco} title='My Investmnets'>
                <Table columns={columns} data={data} className={st.table} />
                <Link to='/investments/request'>
                    <Button color='accent-reverse' className={st.create_btn}>
                        Create new
                    </Button>
                </Link>
            </DefaultSection>
        </div>
    );
}

export default Investments;
