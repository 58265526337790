// router
import { Redirect, Route } from 'react-router-dom';

import { observer } from 'mobx-react';

// View models Provider
import { useViewModels } from '../../view-model/ViewModelProvider';

// pages / Auth
import Signin from '../auth/Signin';
import Signup from '../auth/Signup';

// pages / investments
import Investments from '../dashboard/investments';
import InvestmentsRequest from '../dashboard/InvestmentsRequest';

// layout
import DashboardLayout from '../../layouts/DashboardLayout';
import Settings from '../dashboard/settings';
import Dashboard from '../dashboard';
import Contracts from '../dashboard/Contracts';
import Prizes from '../dashboard/Prizes';

const App: React.FC = observer(() => {
	const {
		authViewModel: { isUserAuthorized },
	} = useViewModels();
	return (
		<>
			{isUserAuthorized ? (
				<DashboardLayout>
					<Route path='/' exact>
						<Dashboard />
					</Route>
					<Route path='/investments'>
						<Route path='/investments' exact>
							<Investments />
						</Route>
						<Route path='/investments/request' exact>
							<InvestmentsRequest />
						</Route>
					</Route>
					<Route path='/all_prizes'>
						<Prizes />
					</Route>
					<Route path='/contracts'>
						<Contracts />
					</Route>
					<Route path='/settings'>
						<Settings />
					</Route>
				</DashboardLayout>
			) : (
				<Redirect to='/account/signin' />
			)}
			<Route path='/account'>
				{!isUserAuthorized ? (
					<>
						<Route path='/account/signup' exact>
							<Signup />
						</Route>
						<Route path='/account/signin' exact>
							<Signin />
						</Route>
					</>
				) : (
					<Redirect to='/' />
				)}
			</Route>
		</>
	);
});

export default App;
