import React, { useMemo } from 'react';

// components
import Button from '../../../components/Button';
import Table from "../../../components/Table"
import DefaultSection from '../../../components/sections/DefaultSection';

// styles
import st from './Contracts.module.scss';


//images || icons
import ContactsIcon from '../../../assets/icons/menus/ContactsIconPurpur.svg';


const Contracts: React.FC = () => {

    const data = useMemo(
        () => [
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '2',
                col4: 'Stocks',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_green' unclickable>Complete</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '12',
                col4: 'Art',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_yellow' unclickable>Processing</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '4',
                col4: 'Crowdfounding',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_red' unclickable>Declined</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '2',
                col4: 'Stocks',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_green' unclickable>Complete</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '12',
                col4: 'Art',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_yellow' unclickable>Processing</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '4',
                col4: 'Crowdfounding',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_red' unclickable>Declined</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '2',
                col4: 'Stocks',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_green' unclickable>Complete</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '12',
                col4: 'Art',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_yellow' unclickable>Processing</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '4',
                col4: 'Crowdfounding',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_red' unclickable>Declined</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '2',
                col4: 'Stocks',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_green' unclickable>Complete</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '12',
                col4: 'Art',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_yellow' unclickable>Processing</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
            {
                col1: '22411224',
                col2: 'Full name',
                col3: '4',
                col4: 'Crowdfounding',
                col5: '11/11/21',
                col6: <Button size='xs' color='sq_red' unclickable>Declined</Button>,
                col7: <Button size='xxs' color='empty'>View</Button>,
            },
        ],
        []
    )

    const columns = useMemo(
        () => [
            {
                Header: 'Contract ID',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Contact person',
                accessor: 'col2',
            },
            {
                Header: 'Docs',
                accessor: 'col3',
            },
            {
                Header: 'Type',
                accessor: 'col4',
            },
            {
                Header: 'Date',
                accessor: 'col5',
            },
            {
                Header: 'Status',
                accessor: 'col6',
            },
            {
                Header: 'View',
                accessor: 'col7',
            },
        ],
        []
    )

    return (
        <div className={st.container}>
            <DefaultSection rightBlock headPadding icon={ContactsIcon} title='My Contracts' rightBlockTitle='Showing date:'>
                <Table dashed columns={columns} data={data} className={st.table} />
            </DefaultSection>
        </div>
    );
}

export default Contracts;
