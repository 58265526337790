import React from 'react';
import st from './Logo.module.scss';
import classNames from 'classnames';

let cx = classNames.bind(st);

const Logo = ({ color = 'light', size = 'md', className = null }) => {
	return (
		<div
			className={cx(
				{
					container: true,
					size_md: size === 'md',
					size_sm: size === 'sm',
				},
				className,
			)}
		>
			{color === 'light' ? (
				<img src='/images/logo-light.svg' width='164px' alt='feasibl' height='50px' />
			) : color === 'accent' ? (
				<img src='/images/logo-accent.svg' width='164px' alt='feasibl' height='50px' />
			) : (
				<p>default Logo</p>
			)}
		</div>
	);
};

export default Logo;
