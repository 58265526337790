import AuthRepository from '../../repository/auth/AuthRepository';
import AuthHolder from '../../entity/auth/AuthHolder';

export default class LoginUseCase {
    private authRepository: AuthRepository;
    private authHolder: AuthHolder;

    public constructor(authRepository: AuthRepository, authHolder: AuthHolder) {
        this.authRepository = authRepository;
        this.authHolder = authHolder;
    }

    /**
     * @throws {Error} if credentials are not valid or have not passed
     */
    public async register(type: string, email: string, password: string): Promise<any> {
        let response = await this.authRepository.register(type, email, password);

        // @TODO
        // this.authHolder.onSignedUp(authResult.authorizationToken);
        return response;
    }
}
