import React from 'react';
import st from './UserAvatar.module.scss';
import ArrowIcon from "../../assets/icons/arrow_long.svg";


type UserAvatarProps = {
    src?: string
    onUploadBtn: () => void
}

const UserAvatar: React.FC<UserAvatarProps> = ({
    src = '/images/avatar_template.svg',
    onUploadBtn
}) => {
    return (
        <div className={st.container}>
            <img src={src} alt='avatar' />
            <div onClick={onUploadBtn} className={st.upload}><img src={ArrowIcon} alt='' /></div>
        </div>
    )
}


export default UserAvatar
