import { action, computed, makeAutoObservable } from 'mobx';

export default class AuthHolder {
    private isAuthorized: boolean;
    private accessToken: string;
    private refreshToken: string;

    public constructor(isAuthorized: boolean, accessToken: string, refreshToken: string) {
        makeAutoObservable(this);
        this.isAuthorized = isAuthorized;
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }

    @action public onSignedIn(accessToken: string, refreshToken: string): void {
        this.isAuthorized = true;
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }

    @action public onSignedOut(): void {
        this.isAuthorized = false;
        this.accessToken = '';
        this.refreshToken = '';
    }

    @action public onSignedUp(): void {
        this.isAuthorized = false;
        this.accessToken = '';
        this.refreshToken = '';
    }

    @action public onTokenUpdate(accessToken: string, refreshToken: string): void {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }

    @computed public get isUserAuthorized(): boolean {
        return this.isAuthorized;
    }

    /**
     * @throws {Error} if user is not authorized
     */
    public getAccessToken(): string {
        if (!this.isAuthorized) {
            throw new Error('User is not authorized');
        }

        return this.accessToken;
    }

    public getRefreshToken(): string {
        // if (!this.isAuthorized) {
        //     throw new Error('User is not authorized');
        // }

        return this.refreshToken;
    }
}
