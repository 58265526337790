import React from 'react';
import { Link } from 'react-router-dom';

// view models
import { useViewModels } from '../../view-model/ViewModelProvider';

// assets / icons
import DashboardIcon from '../../assets/icons/menus/DashboardIcon.svg';
import ChatIcon from '../../assets/icons/menus/ChatIcon.svg';
import InvestmentsIcon from '../../assets/icons/menus/InvestmentsIcon.svg';
import PrizesIcon from '../../assets/icons/menus/PrizesIcon.svg';
import NetWorthIcon from '../../assets/icons/menus/NetWorthIcon.svg';
import ContactsIcon from '../../assets/icons/menus/ContactsIcon.svg';
import SettingsIcon from '../../assets/icons/menus/SettingsIcon.svg';
import WithBlur from '../../components/WithBlur';
import { observer } from 'mobx-react';

// components
import Logo from '../../components/Logo';
import NavigationLink from '../../components/NavigationLink';
import NotificationIndicator from '../../components/NotificationIndicator';
import UserAvatar from '../../components/UserAvatar';

// styles
import st from './DashboardLayout.module.scss';

const DashboardLayout: React.FC = observer(({ children }) => {
	const {
		authViewModel: { onClickSignOut },
	} = useViewModels();
	return (
		<div className={st.layout}>
			<aside className={st.aside}>
				<Link to='/#'>
					<Logo color='accent' className={st.logo} />
				</Link>
				<nav className={st.navigation}>
					<ul>
						<li>
							<NavigationLink icon={DashboardIcon} to='/'>
								Dashboard
							</NavigationLink>
						</li>
						<WithBlur>
							<li>
								<NavigationLink icon={ChatIcon} to='#'>
									Chat
								</NavigationLink>
							</li>
						</WithBlur>
						<li>
							<NavigationLink icon={InvestmentsIcon} to='/investments'>
								My Investments
							</NavigationLink>
						</li>
						<li>
							<NavigationLink icon={PrizesIcon} to='/all_prizes'>
								Prizes
							</NavigationLink>
						</li>
						<WithBlur>
							<li>
								<NavigationLink icon={NetWorthIcon} to='#'>
									Net-Worth
								</NavigationLink>
							</li>
						</WithBlur>
						<li>
							<NavigationLink icon={ContactsIcon} to='/contracts'>
								Contracts
							</NavigationLink>
						</li>
						<li>
							<NavigationLink icon={SettingsIcon} to='/settings'>
								Settings
							</NavigationLink>
						</li>
						{/* <li><NavigationLink icon={SingOutIcon} to='/'>Sign Out</NavigationLink></li> */}
					</ul>
				</nav>
			</aside>
			<div className={st.top_panel}>
				<div className={st.top_panel__right_side}>
					<div className={st.notifications}>
						<NotificationIndicator />
					</div>
					<div className={st.account}>
						<UserAvatar />
						<div className={st.account_inner}>
							<ul className={st.account_actions}>
								<li>
									<Link to='/settings'>
										<p>Settings</p>
									</Link>
								</li>
								<li onClick={onClickSignOut}>
									<p>Sign out</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<main className={st.main}>{children}</main>
		</div>
	);
});

export default DashboardLayout;
