import React from 'react';
import Field from '../../../components/Field';
// import Checkbox from '../../../components/Checkbox';

import { Form, Field as FinalField } from 'react-final-form'

// styles
import st from '../Auth.module.scss';

// icons
// import EmailIco from '../../public/images/icons/email.svg';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useViewModels } from '../../../view-model/ViewModelProvider';
import GoogleLogin from 'react-google-login';
import Logo from '../../../components/Logo';


// types

//
// вынести валидацию отдельно и передавать в Form (возможно через какой-то сервис)


const Signin: React.FC = observer(() => {
    const { authViewModel: {
        onClickSignIn,
        signInSubmitError,
        isSuccessedRegistration,
        isShowError,
        onClickGoogleLogin
    } } = useViewModels();

    const onSubmit = async (values: { email: string, password: string }) => {
        onClickSignIn(values.email, values.password);
    }

    return (
        <div className={st.page}>
            <div className={st.container}>
                <a className={st.to_homepage} href='https://feasibl.dev.basis33.com/'>Back to homepage</a>
                <div className={st.parts}>
                    <div className={st.part}>
                        <a href='https://feasibl.dev.basis33.com/'><Logo color='accent' className={st.logo} /></a>
                        <header className={st.header}>
                            <span>Start For Free</span>
                            <h1>Log In To Feasibl</h1>
                            <p>
                                New one? <Link to='/account/signup'>Sign Up</Link> in 2 minutes
                            </p>
                        </header>
                        <Form
                            onSubmit={(onSubmit)}
                            validate={(values) => {
                                const errors: any = {};
                                if (values.email === undefined) {
                                    errors.email = 'Is required';
                                }
                                if (values.password === undefined) {
                                    errors.password = 'Is required';
                                }
                                if (values.password?.length < 6) {
                                    errors.password = 'less than 6';
                                }
                                return errors
                            }}
                            render={({ handleSubmit, dirtyFieldsSinceLastSubmit }) => (
                                <form className={st.form} onSubmit={handleSubmit}>
                                    {isSuccessedRegistration &&
                                        <p className={st.successed}>Registration completed successfully!</p>
                                    }
                                    <main className={st.form__main}>
                                        <div className={st.form__field}>
                                            <FinalField name='email' render={({ input, meta }) => (
                                                <Field
                                                    error={meta.error}
                                                    isInvalid={meta.error && meta.touched && !meta.dirtySinceLastSubmit}
                                                    title='Email'
                                                >
                                                    <input
                                                        {...input}
                                                        autoComplete='new-password'
                                                        type='email'
                                                        placeholder='name@mail.com'
                                                    />
                                                </Field>
                                            )} />
                                        </div>
                                        <div className={st.form__field}>
                                            <FinalField name='password' render={({ input, meta }) => (
                                                <Field
                                                    title='Password'
                                                    error={meta.error}
                                                    isInvalid={meta.error && meta.touched && !meta.dirtySinceLastSubmit}
                                                >
                                                    <input type='password'  {...input} autoComplete='off' placeholder='6+ characters' />
                                                </Field>
                                            )} />
                                        </div>
                                        {isShowError && !Object.keys(dirtyFieldsSinceLastSubmit).length &&
                                            <p className={st.error}>
                                                {signInSubmitError}
                                            </p>
                                        }
                                    </main>

                                    <div className={st.form__buttons}>
                                        <Button className={st.form__button} color='accent' type='submit' >
                                            Log In
                                        </Button>
                                        <GoogleLogin
                                            clientId='740071620552-usl3ajgqv6cdhjls9bbun7p6d6rcjr0d.apps.googleusercontent.com'
                                            render={renderProps => (
                                                <Button className={st.form__button} onClick={renderProps.onClick} color='outline'>
                                                    Log In With Google
                                                </Button>
                                            )}
                                            buttonText='Login'
                                            onSuccess={({ accessToken }: any) => {
                                                onClickGoogleLogin(accessToken)
                                            }}
                                            onFailure={(e) => {
                                                console.log(e);

                                            }}
                                        />

                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})


export default Signin
