import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';
import App from './presentation/view/app/App';

// settings
import './data/settings/axios';

// styles
import './presentation/scss/shared.scss';
import { ViewModelsProvider } from './presentation/view-model/ViewModelProvider';


ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ViewModelsProvider>
                <App />
            </ViewModelsProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

