import React from 'react';
import st from './Field.module.scss';
import classNames from 'classnames';

type FieldProps = {
    title: string,
    icon?: React.ReactNode,
    className?: string,
    color?: string,
    error?: string,
    isInvalid?: boolean
}


const Field: React.FC<FieldProps> = ({
    title,
    icon,
    children,
    isInvalid,
    error,
    color = 'default',
    className
}) => {
    const classes = classNames(
        st.container,
        {
            [st.color_default]: color === 'default',
            [st.color_danger]: isInvalid || color === 'danger',
        },
        className
    )
    return (
        <div className={classes}>
            { title &&
                <div className={st.title}>
                    {title}
                    {isInvalid &&
                        <span className={st.error}>
                            {error}
                        </span>
                    }
                </div>
            }
            <div className={st.field}>
                <div className={st.field_wrapper}>
                    {typeof children === 'function'
                        ? children()
                        : children}
                </div>
                {icon &&
                    <div className={st.icon}>
                        {icon}
                    </div>
                }
            </div>
        </div>
    )
}


export default Field
