import React from "react";
import AuthApi from "../../data/auth/AuthApi";
import AuthHolder from "../../domain/entity/auth/AuthHolder";
import ExpiredUseCase from "../../domain/interactors/auth/ExpiredUseCase";
import LoginUseCase from "../../domain/interactors/auth/LoginUseCase";
import RegisterUseCase from "../../domain/interactors/auth/RegisterUseCase";
import AuthService from "../services/AuthService";
import AuthViewModel from "./auth/AuthViewModel";

type ViewModelsContextValue = {
    authViewModel: AuthViewModel
}

// data layer
const authRepository = new AuthApi();
const authService = new AuthService();

// domain layer
const authHolder = new AuthHolder(authService.isClientHasTokens(), authService.getRefreshToken(), authService.getAccessToken());
const loginUseCase = new LoginUseCase(authRepository, authHolder);
const registerUseCase = new RegisterUseCase(authRepository, authHolder);
const expiredUseCase = new ExpiredUseCase(authRepository, authHolder)

// view layer
const authViewModel = new AuthViewModel(loginUseCase, registerUseCase, expiredUseCase, authHolder);
const ViewModelsContext = React.createContext<ViewModelsContextValue>({} as ViewModelsContextValue);


export const ViewModelsProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children
}) => {
    return (
        <ViewModelsContext.Provider value={{ authViewModel }}>
            {children}
        </ViewModelsContext.Provider>
    )
}


export const useViewModels = () => React.useContext(ViewModelsContext)
