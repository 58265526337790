import React from 'react';
import st from './UserScore.module.scss';
import EditIco from "../../assets/icons/edit.svg";


type DefaultSectionProps = {
    name: string,
    investments: number,
    avatar: string,
    graph: string
}

const UserScore: React.FC<DefaultSectionProps> = ({
    name,
    investments,
    avatar,
    graph
}) => {
    return (
        <div className={st.user_score}>
            <div className={st.user_score__avatar}>
                <img src={avatar} alt='' />
            </div>
            <div className={st.user_score__name}>{name}</div>
            <div className={st.user_score__graph}>
                <img src={graph} alt='' />
            </div>
            <div className={st.user_score__investments}><span>{investments}</span> Investments</div>
            <div className={st.user_score__edit}><img src={EditIco} alt='' /></div>
        </div>
    )
}

export default UserScore
