import AuthRepository from '../../repository/auth/AuthRepository';
import AuthHolder from '../../entity/auth/AuthHolder';

export default class LoginUseCase {
    private authRepository: AuthRepository;
    private authHolder: AuthHolder;

    public constructor(authRepository: AuthRepository, authHolder: AuthHolder) {
        this.authRepository = authRepository;
        this.authHolder = authHolder;
    }

    /**
     * @throws {Error} if credentials are not valid or have not passed
     */
    public async loginUser(email: string, password: string): Promise<void> {
        const authResult = await this.authRepository.login(email, password);

        this.authHolder.onSignedIn(authResult.data.token, authResult.data.refresh_token);

        return authResult;
    }

    public async googleLogin(googleToken: string): Promise<void> {
        const authResult = await this.authRepository.googleLogin(googleToken);

        this.authHolder.onSignedIn(authResult.data.token, authResult.data.refresh_token);

        return authResult;
    }
}
