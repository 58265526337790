import AuthRepository from '../../repository/auth/AuthRepository';
import AuthHolder from '../../entity/auth/AuthHolder';

export default class ExpiredUseCase {
    private authRepository: AuthRepository;
    private authHolder: AuthHolder;

    public constructor(authRepository: AuthRepository, authHolder: AuthHolder) {
        this.authRepository = authRepository;
        this.authHolder = authHolder;
    }

    /**
     * @throws {Error} if credentials are not valid or have not passed
     */
    public async updateExpiredToken(refreshToken: string): Promise<void> {
        let response: any = await this.authRepository.getFreshToken(refreshToken);

        this.authHolder.onTokenUpdate(response.data.token, response.data.refresh_token);
    }
}
