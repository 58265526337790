import React from 'react';
import st from './Dashboard.module.scss';

// components
import DefaultSection from "../../components/sections/DefaultSection";
import UserScore from "../../components/UserScore";
import InvestmentPreview from "../../components/InvestmentPreview";

//images || icons
import ScoreboardIco from '../../assets/icons/scoreboard.svg';

const Dashboard: React.FC = () => {
    return (
        <div className={st.container}>
            <InvestmentPreview
                cat='Art'
                title='Piece of Art'
                image='/images/product_2.jpg'
                short_desc='Miami, FL'
                desc='Canvas, oil. 100x100. Young artist from Miami. His art focused on dichotomies such as wealth versus poverty, integration versus segregation, and inner versus outer experience. '
                hasInvestBtn
                featured
                clickable
            />
            <DefaultSection rightBlock icon={ScoreboardIco} title='Scoreboard'>
                <div className={st.scoreboard}>
                    <UserScore name='Alex Smith' investments={133} avatar='images/avatar.jpg' graph='images/graph.svg' />
                    <UserScore name='Alex S dsad dmith' investments={23} avatar='images/avatar.jpg' graph='images/graph_down.svg' />
                    <UserScore name='Alex Smisasa sth' investments={22} avatar='images/avatar.jpg' graph='images/graph.svg' />
                </div>
            </DefaultSection>
        </div>
    );
}

export default Dashboard;
