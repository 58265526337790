import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import st from './CustomSelect.module.scss';

import ArrowIco from '../../assets/icons/arrow.svg';

const CustomSelect = ({
	placeholder = 'Select',
	alignIcon,
	value = 'Selected value',
	onSelect = () => {},
	list = [],
	className,
}) => {
	const [isActive, setIsActive] = useState(false);

	const handleClickOutside = (e) => {
		if (
			!e.target.classList.contains(st.list_item) &&
			!e.target.classList.contains(st.placeholder)
		) {
			setIsActive(false);
		}
	};

	const handleClickInside = (e) => {
		setIsActive(true);
	};

	const handleOptionClick = (e) => {
		onSelect({
			value: e.target.getAttribute('data-value'),
			name: e.target.getAttribute('data-name'),
		});
	};

	useEffect(() => {
		window.addEventListener('mousedown', handleClickOutside);
		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div
			className={classNames(
				st.container,
				{
					[st.is_active]: isActive,
					[st.icon_align_right]: alignIcon === 'right',
				},
				className,
			)}
			onClick={handleClickInside}
		>
			{/* <div className={st.placeholder}>*/}
			{/*    {placeholder}*/}
			{/*     <img src={ArrowIco} alt="" />*/}
			{/*</div>*/}
			<div className={st.value}>{value}</div>
			<div className={st.icon}>
				<img src={ArrowIco} alt='' />
			</div>
			<ul className={st.list}>
				{list.map((element, i) => {
					return (
						<li
							className={st.list_item}
							data-value={element.value}
							data-name={element.name}
							onClick={handleOptionClick}
							key={i}
						>
							{element.name}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default CustomSelect;
