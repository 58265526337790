import axios from 'axios';
import { action, computed, makeAutoObservable } from 'mobx';
import AuthHolder from '../../../domain/entity/auth/AuthHolder';
import ExpiredUseCase from '../../../domain/interactors/auth/ExpiredUseCase';
import LoginUseCase from '../../../domain/interactors/auth/LoginUseCase';
import RegisterUseCase from '../../../domain/interactors/auth/RegisterUseCase';

// @TODO (separate this to different stores)
export default class AuthViewModel {
    public isShowError: boolean;
    public signUpSubmitError: string;
    public signInSubmitError: string;
    public isSuccessedRegistration: boolean;

    private loginUseCase: LoginUseCase;
    private registerUseCase: RegisterUseCase;
    private expiredUseCase: ExpiredUseCase;

    private authHolder: AuthHolder;

    constructor(
        loginUseCase: LoginUseCase,
        registeruseCase: RegisterUseCase,
        expiredUseCase: ExpiredUseCase,
        authHolder: AuthHolder,
    ) {
        makeAutoObservable(this);
        this.isShowError = false;
        this.signUpSubmitError = '';
        this.signInSubmitError = '';

        // use cases
        this.loginUseCase = loginUseCase;
        this.registerUseCase = registeruseCase;
        this.expiredUseCase = expiredUseCase;

        this.isSuccessedRegistration = false;
        this.authHolder = authHolder;

        this.runInterceptor();
    }

    @action public onClickSignIn = async (email: string, password: string): Promise<void> => {
        this.isShowError = false;
        this.loginUseCase
            .loginUser(email, password)
            .then(({ data }: any) => {
                this.updateTokensStorage(data.token, data.refresh_token);
            })
            .catch(({ response }) => {
                if (response.status === 401) {
                    this.signInSubmitError = response.data.message;
                    this.isShowError = true;
                }
            });
    };

    @action public onClickGoogleLogin = async (googleToken: string): Promise<void> => {
        this.isShowError = false;
        this.loginUseCase
            .googleLogin(googleToken)
            .then(({ data }: any) => {
                this.updateTokensStorage(data.token, data.refresh_token);
            })
            .catch(({ response }) => {
                if (response.status === 401) {
                    this.signInSubmitError = response.data.message;
                    this.isShowError = true;
                }
            });
    };

    @action public onClickSignUp = (
        type: string,
        email: string,
        password: string,
    ): Promise<any> => {
        this.isShowError = false;
        this.isSuccessedRegistration = false;

        return this.registerUseCase
            .register(type, email, password)
            .then(
                action((response) => {
                    this.isSuccessedRegistration = true;
                    return response;
                }),
            )
            .catch(
                action(({ response }) => {
                    this.signUpSubmitError = response.data['hydra:description'];

                    this.isShowError = true;
                    return response;
                }),
            );
    };

    @action public onClickSignOut = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        this.authHolder.onSignedOut();
    };

    @computed public get isUserAuthorized(): boolean {
        return this.authHolder.isUserAuthorized;
    }

    private updateTokensStorage = (accessToken: string, refreshToken: string) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    };

    private runInterceptor = () => {
        axios.defaults.headers.common['Content-Type'] = 'application/ld+json';
        if (this.authHolder.isUserAuthorized) {
            axios.interceptors.response.use(
                (response) => response,
                (error) => {
                    const originalRequest = error.config;
                    if (error.response.status === 401 && !originalRequest._retry) {
                        this.expiredUseCase
                            .updateExpiredToken(this.authHolder.getRefreshToken())
                            .then((res: any) => {
                                if (res.status === 201) {
                                    this.updateTokensStorage(
                                        res.data.token,
                                        res.data.refresh_token,
                                    );
                                    return axios(originalRequest);
                                }
                            });
                    }
                    return Promise.reject(error);
                },
            );
        }
    };
}
