import React from 'react';
import st from './Settings.module.scss';
import { Form } from 'react-final-form'
import Field from '../../../components/Field';
import Button from "../../../components/Button"
import UserAvatarDashboard from "../../../components/UserAvatarDashboard"
import DocIco from '../../../assets/icons/doc_icon.svg';
import DefaultSection from '../../../components/sections/DefaultSection';
import UserScore from '../../../components/UserScore';

const Settings: React.FC = () => {
    return (
        <div className={st.container}>
            <Form
                onSubmit={() => { }}
                render={({ handleSubmit }) => (
                    <form className={st.settings_form} onSubmit={handleSubmit}>

                        <DefaultSection title='Settings'>
                            <div className={st.default_section}>
                                <UserAvatarDashboard src='/images/avatar_template.svg' onUploadBtn={() => { }} />
                                <div className={st.settings_form__block}>
                                    <div className={`${st.form__field}  ${st.settings_form__item}`}>
                                        <Field title='FirstName'>
                                            <input type='text' placeholder='John' />
                                        </Field>
                                    </div>
                                    <div className={`${st.form__field}  ${st.settings_form__item}`}>
                                        <Field title='LastName'>
                                            <input type='text' placeholder='Doe' />
                                        </Field>
                                    </div>
                                    <div className={`${st.form__field}  ${st.settings_form__item}`}>
                                        <Field title='Email'>
                                            <input type='email' placeholder='example@gmail.com' />
                                        </Field>
                                    </div>
                                    <div className={`${st.form__field}  ${st.settings_form__item}`}>
                                        <Field title='Password'>
                                            <input type='password' placeholder='****' />
                                        </Field>
                                    </div>
                                    <div className={`${st.form__field}  ${st.settings_form__item}`}>
                                        <Field title='Address'>
                                            <input type='text' placeholder='Hanover MA' />
                                        </Field>
                                    </div>
                                    <div className={`${st.form__field}  ${st.settings_form__item}`}>
                                        <Field title='State'>
                                            <input type='text' placeholder='Washington St' />
                                        </Field>
                                    </div>
                                    <div className={`${st.form__field}  ${st.settings_form__item}`}>
                                        <Field title='Phone'>
                                            <input type='tel' placeholder='Your phone' />
                                        </Field>
                                    </div>
                                    <div className={st.settings_form__item}>
                                        <label
                                            className={st.settings_form__label}>WhatsApp</label>
                                        <Button
                                            className={st.settings_form__btn}
                                            color='green_transparent'>Connect</Button>
                                    </div>
                                </div>
                            </div>
                        </DefaultSection>
                        <DefaultSection title='Notifications'>
                            <div className={st.default_section}>
                                <div className={st.settings_checkboxes}>
                                    <div
                                        className={st.settings_checkboxes__item}>
                                        <input type='checkbox'
                                            name='checkbox-cats[]'
                                            id='checkbox-1' value='1' />
                                        <label htmlFor='checkbox-1'>Email
                                                notification</label>
                                    </div>

                                    <div
                                        className={st.settings_checkboxes__item}>
                                        <input type='checkbox'
                                            name='checkbox-cats[]'
                                            id='checkbox-2' value='2' />
                                        <label htmlFor='checkbox-2'>Phone
                                                notification</label>
                                    </div>
                                    <div
                                        className={st.settings_checkboxes__item}>
                                        <input type='checkbox'
                                            name='checkbox-cats[]'
                                            id='checkbox-3' value='3' />
                                        <label htmlFor='checkbox-3'>WhatsApp
                                                notification</label>
                                    </div>
                                </div>
                                <div className={st.settings_documents}>
                                    <div className={st.settings_documents__title}>Documents</div>
                                    <div className={st.settings_documents__item}>
                                        <div className={st.settings_documents__info}>
                                            <div className={st.settings_documents__img}><img src={DocIco} alt='' /></div>
                                            <div>
                                                <p>ID.pdf</p>
                                                <span>Oct 21, 2020 at 12:38 am</span>
                                            </div>
                                        </div>
                                        <div className={st.settings_documents__size}>23,1 Mb</div>
                                    </div>
                                    <div className={st.settings_documents__item}>
                                        <div className={st.settings_documents__info}>
                                            <div className={st.settings_documents__img}><img src={DocIco} alt='' /></div>
                                            <div>
                                                <p>Document.doc</p>
                                                <span>Oct 21, 2020 at 12:38 am</span>
                                            </div>
                                        </div>
                                        <div className={st.settings_documents__size}>23,1 Mb</div>
                                    </div>
                                    <Button className={st.settings_documents__btn} color='sea_dashed'>Upload file</Button>
                                    <Button className={st.settings_documents__submit} color='accent-reverse'>Update</Button>
                                </div>

                            </div>
                        </DefaultSection>
                    </form>
                )}
            />

            <DefaultSection title='Partners'>
                <div className={st.scoreboard}>
                    <UserScore name='Alex Smith' investments={133}
                        avatar='/images/avatar.jpg'
                        graph='/images/graph.svg' />
                    <UserScore name='Alex S dsad dmith' investments={23}
                        avatar='/images/avatar.jpg'
                        graph='/images/graph_down.svg' />
                    <UserScore name='Alex Smisasa sth' investments={22}
                        avatar='/images/avatar.jpg'
                        graph='/images/graph.svg' />
                </div>
            </DefaultSection>
        </div>
    );
}

export default Settings;
