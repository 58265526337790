import React from 'react';
import { NavLink } from 'react-router-dom';
import st from './NavigationLink.module.scss';
import styles from 'classnames';

type NavigationLinkProps = {
    to: string,
    icon: any,
    active: boolean,
    children: any
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
    to,
    icon,
    active,
    children
}) => {
    return (
        <NavLink to={to} className={styles(st.container, {[st.active] : active})}>
            <div className={st.icon}><img src={icon} alt='' /></div>
            <div className={st.title}> {children} </div>
        </NavLink>
    )
}

export default NavigationLink
