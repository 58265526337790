import React from 'react';

// components
import DefaultSection from '../../../components/sections/DefaultSection';
import CustomSelect from '../../../components/CustomSelect';
import Field from '../../../components/Field';
import Button from '../../../components/Button';

// icons
import { ReactComponent as VideoIco } from '../../../assets/icons/video.svg';
import { ReactComponent as AddPictureIco } from '../../../assets/icons/add_picture.svg';
import { ReactComponent as PDFIco } from '../../../assets/icons/pdf.svg';

// styles
import st from './InvestmentsRequest.module.scss';

const InvestmentsRequest: React.FC = () => {
    return (
        <div className={st.container}>
            <DefaultSection title='Request investment creation' >
                <form className={st.form}>
                    <main className={st.form__main}>
                        <div className={st.form__row}>
                            <Field
                                title='Your Name'
                            >
                                <input type='text' placeholder='Full Name ' />
                            </Field>
                        </div>

                        <div className={st.form__row}>
                            <Field
                                title='Email'
                            >
                                <input type='email' placeholder='name@mail.com' />
                            </Field>
                        </div>

                        <div className={st.form__row}>
                            <Field
                                title='Category'>
                                <CustomSelect
                                    className={st.form__select}
                                    alignIcon='right'
                                    value='Select'
                                    list={[
                                        {
                                            name: 'name 1',
                                            value: 'value 1'
                                        },
                                        {
                                            name: 'name 2',
                                            value: 'value 2'
                                        },
                                        {
                                            name: 'name 3',
                                            value: 'value 3'
                                        }
                                    ]} />
                            </Field>
                        </div>
                        <div className={st.form__row}>
                            <div className={st.form__col}>
                                <Field
                                    title='Required Amount'>
                                    <CustomSelect
                                        className={st.form__select}
                                        value='1'
                                        alignIcon='right'
                                        list={[
                                            {
                                                name: 'name 1',
                                                value: 'value 1'
                                            },
                                            {
                                                name: 'name 2',
                                                value: 'value 2'
                                            },
                                            {
                                                name: 'name 3',
                                                value: 'value 3'
                                            }
                                        ]} />
                                </Field>
                            </div>
                            <div className={st.form__col}>
                                <Field
                                    title='Fractions'>
                                    <CustomSelect
                                        className={st.form__select}
                                        value='1'
                                        alignIcon='right'
                                        list={[
                                            {
                                                name: 'name 1',
                                                value: 'value 1'
                                            },
                                            {
                                                name: 'name 2',
                                                value: 'value 2'
                                            },
                                            {
                                                name: 'name 3',
                                                value: 'value 3'
                                            }
                                        ]} />
                                </Field>
                            </div>
                        </div>
                    </main>
                    <div className={st.form__second}>
                        <div className={st.attach}>
                            <div className={st.attach__title}>Upload Files</div>
                            <div className={st.attach__desc}>Pictures, Video, Business Plan & Pitchdeck</div>
                            <ul>
                                <li><AddPictureIco />Pictures File Size - 10MB and less</li>
                                <li><VideoIco />1 minute Video presentation (Max 1GB)</li>
                                <li><PDFIco /> Business plan – PDF</li>
                                <li><PDFIco /> Pitchdeck – PDF</li>
                            </ul>
                            <Button color='accent-reverse'>Upload</Button>
                        </div>
                        <div className={st.form__row}>
                            <Field
                                title='Message'
                            >
                                <textarea maxLength={900} rows={5} placeholder='Leave your message here ' />
                            </Field>
                        </div>
                        <div className={st.form__row}>
                            <Button color='accent' className={st.submit_btn}>Create request</Button>
                        </div>
                    </div>
                </form>
            </DefaultSection>
        </div>
    );
}

export default InvestmentsRequest;
